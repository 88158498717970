<template>
   <div class="wrapper">
    <div class="header-img">
      <input type="checkbox" id="input" hidden />
        <div class="box" id="jjjj">
          <div class="row justify-content-center">
            <label for="input" id="im">
              <div class="container acceso">
                <div class="row justify-content-center">
                  <div class="col">
                    <form>
                      <button type="button" @click="returnHome()" class="btn-close" aria-label="Close">X</button>
                      <h1 class="text text-center mt-5 mb-4">Acceso Clientes<br></h1>
                        <div class="form-group mt-3">
                          <label>Por favor, introduce tu email</label>
                          <input type="email" class="form-control form-control-lg" />
                        </div>
                        <div class="form-group mt-3 mb-4">
                          <label>Por favor, introduce tu contraseña</label>
                          <input type="password" class="form-control form-control-lg" />
                        </div>
                        <button type="submit" class="btn btn-dark btn-lg btn-block mb-5">Enviar</button>
                        <p class="msg mb-3 text-center">
                            <router-link to="/password-olvidado">¿Olvidaste tu contraseña?</router-link>
                        </p>
                        <p class="msg mb-4 mt-4 text-center">¿Aún no tienes una cuenta?
                            <router-link to="/registro">Registrate aqui</router-link>
                        </p>
                    </form>
                  </div>
                </div>
              </div>
            </label>
          </div>
          <div class="container-fluid login_footer">
          <div class="row justify-content-center">
            <div class="col">
              <figure>
                <p class="col justify-content-left marca">
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                  <a class="external_link" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
                </p>
                <p class="marca_kbo">© KBO 2021. Marca KBO. Versión 1.0</p>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginClientes',
   data() {
    return {
      email: null,
      password: null,
      isInvalid: false,
    };
  },
  methods: {
      returnHome() {
        this.$router.push('/')
      },
  }
}
</script>

<style lang="scss" src="../scss/estilos.scss" scoped></style>
